<template>
  <div class="d-flex flex-column flex-center flex-column-fluid h-100">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2qx text-danger mb-4">
            {{
              $t(
                "Components.LostWave.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h1>
          <template v-if="errorCode">
            <div
              class="fw-semibold fs-5 mb-3"
              v-html="
                $t(
                  'Components.LostWave.DescriptionWithInternalErrorCode',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
            ></div>
            <span
              class="badge badge-info mb-3 fs-5 cursor-pointer"
              @click="$root.copyClipboard(errorCode.split(':')[1])"
            >
              {{ errorCode.split(":")[1] }}
            </span>
          </template>
          <div class="mb-0">
            <router-link to="/" class="btn btn-primary me-2">
              <i class="bi-app-indicator bi"></i>
              {{
                $t(
                  "Buttons.ReturnHome",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link
            >
            <a class="btn btn-info me-2" @click="$router.go(-1)">
              <i class="bi bi-arrow-left-circle"></i>
              {{
                $t(
                  "Buttons.ReturnToPreviousPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </a>
            <a
              :href="$config.webSites.support"
              class="btn btn-warning"
              target="_blank"
            >
              <i class="bi-people bi"></i>
              {{
                $t(
                  "Buttons.SupportTeam",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</a
            >
          </div>
          <div>
            <div class="browser">
              <div class="controls">
                <i></i>
                <i></i>
                <i></i>
              </div>
              <div class="eye"></div>
              <div class="eye"></div>
              <div class="mouth">
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
                <div class="lips"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InternalError",
  data() {
    return {
      errorCode: null,
    };
  },
  mounted() {
    this.errorCode = this.$store.state.internalErrorDescription;
    if (String.isNullOrWhiteSpace(this.errorCode)) {
      this.$router.push("/");
    }

    this.$root.changeDocumentTitle(
      this.$t(
        "Components.LostWave.Title",
        {},
        { locale: this.$store.state.activeLang }
      )
    );
  },
  setup() {},
};
</script>
